import '../../assests/styles/navbar.css';
import * as ROUTES from "../../utils/Routes";

const NavigationBar = ({dark_color}) => {
  return (
        <div class= {dark_color?"nav-containerDark":"nav-container"}>
            <div class="brand">
                <a href={ROUTES.HOME}>arkulariak.</a>
            </div>
            <nav class={dark_color? "navBarDark":"navBar"}>
                <div class="navItem"><a href={ROUTES.HOME}>INicIO</a></div>
                <div class="navItem"><a href={ROUTES.ARKU}>ASOCIACIÓN</a></div>
                <div class="navItem"><a href={ROUTES.CLUBS}>CLUBS</a></div>
                {/*<div class="navItem"><a href="#">NOTICIAS</a></div>*/}
            </nav>
        </div>
  );
};

export default NavigationBar;

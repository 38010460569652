import '../../assests/styles/home.css';
import '../../assests/styles/hero_text.css';
import '../../assests/styles/trainning.css';
import NavigationBar from '../NavigationBar';

const main_background = require('../../assests/images/Red_Nockbackground.jpg');


const Home = () => {
  return (
    <div style = {{height:"100vh"}}>
      <div class="hero-page hero-page-common commond-grid">  
        <div class="container">  
          <NavigationBar dark_color={false}/>
          <HomeTitle />
        </div>
      </div>
      {/*
      <div class="home-content">
        <div class="commond-grid home-block-container " style={{backgroundColor:"#16202e"}}>  
          <Trainning></Trainning>
        </div>
        <div class="commond-grid home-block-container" >  
          <Trainning></Trainning>
        </div>
        <div class="commond-grid home-block-container" >
          <Trainning></Trainning>  
        </div>
        <div class="commond-grid home-block-container">
          <Trainning></Trainning>  
        </div>
      </div>*/}
    </div>
  );
};


const HomeTitle = () => {
  return (
    <div class="home-text">
      <div class="home-text-content">
        <p class="white-home">Mente</p>
        <p class="white-home">Fuerza</p>
        <p class="white-home">Trabajo</p>
        <p class="red-home">Arkulariak</p>
      </div>
    </div>
  );
};

const Trainning = () => {
  return(
    <div class="trainning-content">
      <div class="trainning-montage" >
        <img class="image" src={require('../../assests/images/collage.jpg')}></img>
        
      </div>
      <div class="trainning-text">
        <h1>CURSOS</h1>
        <p>Tanto si quieres aprender a disparar con un arco y a colocar las flechas en el centro de una diana, o si deseas perfeccionar la técnica que previamente habías aprendido, estos son los cursos que estás buscando.</p>
        <p>Un equipo de entrenadores que te ayudaran a mejorar de forma constante y unas clases participativas (siempre dirigidas por un monitor homologado) con grupos mixtos de 6 a 20 alumnos. Desde los 7 años y sin límite de edad.</p>
        <p>El Club facilita todo el material necesario (arco, flechas, protecciones, dianas, etc.) que el alumno precise hasta que éste lo desee. No supone ningún gasto adicional en ese sentido. Si estás interesado en comprar un arco, también te asesoramos según tu gusto o tu nivel técnico. Si ya tienes arco, lo puedes traer.</p>
      </div>
    </div>
  );
};


export default Home;


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './components/Home'
import Home from "./components/Home";
import Arku from "./components/Arku";
import Clubs from "./components/Clubs";
import * as ROUTES from "./utils/Routes";

function App() {
  return(
    <Router>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home/>}></Route>
        <Route path={ROUTES.ARKU} element={<Arku/>}></Route>
        <Route path={ROUTES.CLUBS} element={<Clubs/>}></Route>
      </Routes>
    </Router>
  )
;}

export default App;

import '../../assests/styles/clubs.css';

import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import NavigationBar from '../NavigationBar';
const main_background = require('../../assests/images/eli.jpg');

const amore = require('../../assests/images/amore_II.jpg');
const durango = require('../../assests/images/durango.jpg');
const eibar = require('../../assests/images/eibar.jpg');
const etxe = require('../../assests/images/etxebarri_II.jpg');
const bilbao = require('../../assests/images/bilbao.jpg');
const galda = require('../../assests/images/galdakao.jpeg');
const lanzarEmail = () => {
    
}

const Clubs = () => {

    return(
        <div class=" commond-grid" >
        <Grid container class="container_clubs"  style={{ height:'100vh' }} xs={12} justifyContent="center" alignItems="center">
            <Grid item >
                <NavigationBar  dark_color={true}/>
            </Grid>
            <Grid item class="clubs-text">
                <h1></h1>
            </Grid>
            <Grid container  spacing={2} justifyContent="center">
                
                {data.map((club) =>
                <Grid item >
                <Card  sx={{ width:350, height:550}} xs={12} md={6} lg={4} xl={4}>
                    <CardMedia
                      component="img"
                      height="350"
                      image={club["img"]}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" flexWrap={true}>
                        {club["nombre"]}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" flexWrap={true}>
                        {club["direccion"]}
                      </Typography>
                      
                      <Typography variant="body2" color="text.secondary">
                        {club["tlf"]}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button onClick={(e) => {
                        window.location.href = "mailto:arkulariak@arkulariak.com";
                        e.preventDefault();
                        }} size="small">Contactar</Button>
                    </CardActions>
                </Card> </Grid>)}
                
              
            </Grid>
        </Grid>
        </div>
      );
};


export default Clubs;

const data = [
    {
        "nombre":"Club Arqueros Durango",
        "tlf": "+34 650 35 17 43",
        "direccion": "Tabira Kalea, 60, 48200 Durango, Bizkaia",
        "img":durango
    },
    {
      "nombre":"Club Arqueros Ostadar Amorebieta",
      "tlf": "+34 650 35 17 43",
      "direccion": "Colegio Público Amorebieta Larrea Herri Ikast Publ.",
      "img":amore
    },
    {
        "nombre":"Arkulariak A.D. de Etxebarri",
        "tlf": "+34 650 35 17 43",
        "direccion": "C.E.P. José Miguel de Barandiaran Avda. San Esteban 16",
        "img":etxe
    },
    
    {
      "nombre":"San Adrian Arkulariak",
      "tlf": "+34 650 35 17 43",
      "direccion": "Bilbao",
      "img":bilbao
  },
    {
        "nombre":"Club Urko Eibar",
        "tlf": "+34 650 35 17 43",
        "direccion": " Isasi Kalea, 37, 20600 Eibar, Gipuzkoa",
        "img":eibar
    },
    {
      "nombre":"Club Arqueros Kasy - Galdakao",
      "tlf": "+34 650 35 17 43",
      "direccion": "C/ Sixta Barrentxea, 13-A Galdakao",
      "img":galda
  },
    {
        "nombre":"La Diana",
        "tlf": "+34 650 35 17 43",
        "direccion": "Club La Diana - Bilbao",
        "img":bilbao
    }
]
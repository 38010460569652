import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import NavigationBar from '../NavigationBar';
import '../../assests/styles/home.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import eli_pi from '../../assests/images/eli.jpg';

import '../../assests/styles/arku.css';
import { Typography, h1 } from '@mui/material';

const darkTheme = createTheme({ palette: { mode: 'dark' } });
const eli_picture = require('../../assests/images/eli.jpg');
const eli_picture2 = require('../../assests/images/eli_tablet.jpg');
const style = { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '..', height: '..'}

const Arku = () => {
    return(
        <div class=" commond-grid">
        <Grid container class="container"  style={{ height:'100vh' }} xs={12} justifyContent="center" >
            <Grid item >
                <NavigationBar  dark_color={true}/>
            </Grid>
            <Grid item  xs={12} container justifyContent="center" style={style} spacing={5} marginTop={1}>
                <Grid item sm={12} md={12} lg={6} xl={4} justifyContent="center" alignContent="center">
                    {/*<img src={eli_picture}></img>*/}
                    <ArkuImg/>
                </Grid>
                <Grid item sm={12} md={12} lg={6} xl={8} alignContent="center" >
                     <h1 class="arku-title" xs={12}>ASOCIACIóN ARKULARIAK</h1>
                     <Grid item xs={12} >
                            <Typography textAlign={'justify'} >El Tiro con Arco además de ser el deporte más antiguo del mundo, es en la actualidad un deporte olímpico. Deporte en el que un buen resultado conjuga una técnica precisa, unos ajustes adecuados en el material utilizado y una buena cantidad de horas disparando.

Si quieres aprender, competir o simplemente divertirte un rato, no dudes en ponerte en contacto con nosotros. En Arkulariak podrás encontrar nuevos amigos con lo que poder practicar este antiquísimo deporte. ¡ Sencillamente prueba ! Estamos convencidos de que te gustará, porque con un poco de técnica y constancia serás capaz de llegar hasta donde te propongas.
</Typography>
<p></p>
<Typography textAlign={'justify'}>Somos una Asociación sin ánimo de lucro constituida al amparo de la Ley 3/1988, y registrada en el registro de Asociaciones del Gobierno Vasco con el número AS/07664/1998.</Typography>
<p></p>
<Typography textAlign={'justify'}>

 
	

Si bien la Asociación tiene un carácter eminentemente privado, pretende fomentar el deporte del tiro con arco también en el ámbito federado.
A tal fin, y de conformidad con la Ley 14/1998, del Deporte del País Vasco, la Asociación ha constituido en su seno una AGUPACIÓN DEPORTIVA con el mismo nombre que la asociación, que figura inscrita en el registro de Asociaciones y Federaciones Deportivas del País Vasco con el número 745, con el fin de inscribirla en la federación Vizcaína de Tiro con Arco y, por tanto, en la Federación Vasca, para que aquellos deportistas de la asociación Arkulariak que así lo deseen, participen, además de en las actividades propias de la asociación, en cuantos campeonatos oficiales estimen oportuno, contando es este caso, con la ayuda y patrocinio de la asociación, de acuerdo al presupuesto y normas que al efecto se establezcan.</Typography>
</Grid>
                        </Grid>
            </Grid>
        </Grid>
        </div>
      );
};


const ArkuImg = () => {
    return (
        <Card  sm={12} md={6} lg={6} xl={4}>
        <CardMedia
          component="img"
          image={eli_picture}
          alt="archer"
        />
    </Card> 
    );
}

export default Arku;